import React from "react";
import styled from "styled-components";
import { Wrapper, Container } from "../../global";

import { FC } from "react";
import { Typography, Grid } from "@material-ui/core";

const TermsOfUse: FC = () => {
  return (
    <Wrapper id="legal">
      <Container>
        <Grid container direction="column">
          <GridItem container item justify="center" alignItems="center">
            <Typography variant="h3" style={{ textAlign: "center", fontWeight: 500 }}>
              AKO MAPS TRIAL PERIOD TERMS OF USE
            </Typography>
          </GridItem>
          <GridItem container item>
            <List>
              <li>
                <Link href="#definitions">Definitions</Link>
              </li>
              <li>
                <Link href="#registering">Registering for the Ako Maps Platform</Link>
              </li>
              <li>
                <Link href="#account">Account</Link>
              </li>
              <li>
                <Link href="#free-trial">Free trial period</Link>
              </li>
              <li>
                <Link href="#license">Licence to use Ako Maps Platform and the Ako Maps Resources</Link>
              </li>
              <li>
                <Link href="#user-content">User Content</Link>
              </li>
              <li>
                <Link href="#usage">Your use of the Ako Maps Platform</Link>
              </li>
              <li>
                <Link href="#no-warranty">No warranties</Link>
              </li>
              <li>
                <Link href="#liability">Liability</Link>
              </li>
              <li>
                <Link href="#personal-information">Personal information and electronic messages</Link>
              </li>
              <li>
                <Link href="#termination">Termination</Link>
              </li>
              <li>
                <Link href="#changes">Changes to these terms</Link>
              </li>
              <li>
                <Link href="#general">General</Link>
              </li>
            </List>
            <LegalList>
              <li id="definitions">
                Definitions
                <ol>
                  <li>
                    <i>Ako Maps Platform</i> means the patented web app used to help facilitate teaching and learning by
                    learning by allowing users to create structured maps that describe topics, video linked
                    presentations, and other resources and is located at the url <Link href="../../">akomaps.com</Link>;
                  </li>

                  <li>
                    <i>Ako Maps Resources</i> means any material, information, videos, tools and online content on the
                    Ako Maps Platform, including materials created by CPL using the Ako Maps Platform, including
                    structured maps, presentations, quizzes and flashcards, user notes and responses to quiz questions,
                    as updated or changed from time to time, but does not include any User Content;
                  </li>
                  <li>
                    <i>CPL</i> means Cognitive Performance Labs (also referred to as &quot;<i>we</i>&quot; and &quot;
                    <i>us</i>&quot;);
                  </li>
                  <li>
                    <i>Other User</i> means a User that has received, for it use, User Content created by another User;
                  </li>
                  <li>
                    <i>Free Trial Period</i> means any period during which you have access to the Ako Maps Platform at
                    no cost;
                  </li>
                  <li>
                    <i>User Content</i> means materials created by Users using the Ako Maps Platform and/or Ako Maps
                    Resources, Ako Maps Resources, including structured maps, presentations, quizzes and flashcards,
                    user notes and responses to quiz questions;
                  </li>
                  <li>
                    <i>User</i> means individuals that are registered to use the Ako Maps Platform (also referred to as
                    “<i>you</i>”.)
                  </li>
                </ol>
              </li>
              <li id="registering">
                Registering for the Ako Maps Platform
                <ol>
                  <li>
                    By ticking the tick-box in relation to accepting these terms and completing and submitting the
                    registration form, you accept and agree to be legally bound to these terms. The Ako Maps Platform is
                    operated by CPL.
                  </li>
                  <li>
                    By registering to use the Ako Maps Platform you warrant that you are at least 18 years old or have
                    your parent’s or guardian’s permission to sign up to the Ako Maps Platform and agree to these terms.
                  </li>
                </ol>
              </li>
              <li id="account">
                Account
                <ol>
                  <li>
                    You must set up an account in order to access and use the Ako Maps Platform and to create User
                    Content.
                  </li>
                  <li>
                    An account can only relate to a single, named individual. Accounts cannot be sold or transferred to
                    other people. You may update or cancel your account at any time. You may not share your account
                    information, including your password, with anyone else.
                  </li>
                  <li>
                    Any information you provide us with when creating your account must be true and correct. You must
                    update us if any of your personal information, including Payment Method, changes.
                  </li>
                  <li>
                    If you think that your account has been accessed by a third party, or that there has been some other
                    breach of security in relation to your account, you must immediately notify us of this breach by
                    contacting us at{" "}
                    <Link href="mailto:office@cognitiveperformancelabs.com">office@cognitiveperformancelabs.com</Link>.
                  </li>
                </ol>
              </li>
              <li id="free-trial">
                Free trial period
                <ol>
                  <li>
                    At the end of the specified Free Trial Period you will be obliged to pay for your continued use of
                    the Ako Maps Platform on the terms that we will notify you of prior to the expiry of the Free Trial
                    Period.
                  </li>
                </ol>
              </li>
              <li id="license">
                Licence to use Ako Maps Platform and the Ako Maps Resources
                <ol>
                  <li>
                    CPL (or its licensors) owns the copyright and other intellectual property rights in the Ako Maps
                    Platform, and any Ako Maps Resources.
                  </li>

                  <li>
                    When you register for the Ako Maps Platform CPL grants you a non-exclusive, non-transferrable
                    licence for the Free Trial Period to use the Ako Maps Resources and the Ako Maps Platform for the
                    purpose of creating and using User Content, subject to these terms.
                  </li>

                  <li>
                    You have a licence to use the Ako Maps Platform and Ako Maps Resources for the Free Trial Period,
                    you do not own the Ako Maps Platform or Ako Maps Resources. Subject to your rights under{" "}
                    <Link href="#user-content">clause 6</Link>, you may not lend, copy, distribute, share, lease, edit,
                    on sell, modify, create derivative works of the Ako Maps Platform or Ako Maps Resources.
                  </li>
                </ol>
              </li>
              <li id="user-content">
                User Content
                <ol>
                  <li>
                    Each User (or their employer if applicable) owns title, and all copyright and other intellectual
                    property rights in all User Content created by that User on the Ako Maps Platform.
                  </li>

                  <li>
                    A User may elect, at their sole discretion, to share their User Content though the Ako Maps
                    Platform, with other Users (“<i>Other Users</i>”).
                  </li>

                  <li>
                    Unless expressly agreed otherwise by the User and the Other User, by sharing User Content though the
                    Ako Maps Platform, the User grants the Other User a non-exclusive licence to use, modify, copy,
                    share and export that User Content for the Other User’s own teaching and/or learning purposes.
                  </li>

                  <li>
                    If CPL considers that a User has infringed the intellectual property rights of another User, CPL may
                    by written notice and without limitation to its other rights and remedies at law or under these
                    terms, require you to immediately destroy, take down or retrieve any infringing copies of the
                    relevant User Content and may terminate your use of the Ako Maps Platform.
                  </li>

                  <li>
                    You share User Content with Other Users and any third parties at your own risk. If an Other User
                    breaches <Link href="#user-content">clause 6</Link> of these terms in respect of User Content owned
                    by you, CPL will not be liable to you in any way.
                  </li>

                  <li>
                    You grant CPL a royalty-free, perpetual, irrevocable licence to use and retain a copy of your User
                    Content for the purpose of administering your account, and the accounts of Other Users you share
                    User Content with, and maintaining the contents of those account following termination of these
                    terms.
                  </li>
                </ol>
              </li>
              <li id="usage">
                Your use of the Ako Maps Platform
                <ol>
                  <li>
                    You must not:
                    <ol>
                      <li>
                        use the Ako Maps Platform or create User Content for any unlawful purpose or in a way which
                        breaches any third party’s rights;
                      </li>

                      <li>access the Ako Maps Platform in an unauthorised manner;</li>

                      <li>
                        do anything that could place an unreasonable or disproportionately large load on the Ako Maps
                        Platform;
                      </li>

                      <li>make any modifications to the Ako Maps Platform;</li>

                      <li>test, scan or circumvent the security of the Ako Maps Platform; or</li>

                      <li>
                        knowingly transmit any virus, worm, defect, Trojan horse or any other malicious code to the Ako
                        Maps Platform of through any User Content.
                      </li>
                    </ol>
                  </li>

                  <li>
                    You must not submit or upload content to the Ako Maps Platform, or create User Content, where you
                    have no right to do so, or where that content is, or is intended to be, misleading or deceptive,
                    defamatory, harassing, threatening, bigoted, hateful, violent, vulgar, obscene, pornographic, or
                    otherwise offensive or that harms or can reasonably be expected to harm any person or entity.
                  </li>

                  <li>
                    CPL may remove any User Content or other material from the Ako Maps Platform that it considers, in
                    its sole discretion, breaches these terms without giving notice to you.
                  </li>
                </ol>
              </li>
              <li id="no-warranty">
                No Warranties
                <ol>
                  <li>
                    If you are a consumer you have rights under the Consumer Guarantees Act 1993, and nothing in this{" "}
                    <Link href="#no-warranty">clause 8</Link> affects your statutory rights.
                  </li>

                  <li>
                    CPL does not warrant that the Ako Maps Platform (or any of its functions) will be uninterrupted or
                    error-free, that defects will be corrected, or that the Ako Maps Platform, or its servers, will be
                    free of viruses or other harmful components.
                  </li>

                  <li>
                    If you choose to use the Ako Maps Platform, or export any Ako Maps Resources or User Content or
                    anything else from the Ako Maps Platform, you do so at your own risk, and you will be solely
                    responsible for any damage to your computer system or loss of data that results.
                  </li>

                  <li>
                    All of the Ako Maps Resources, and any User Content, are provided for your information purposes
                    only. Neither CPL nor any of its employees make any express or implied warranties regarding the
                    accuracy, currency or completeness of the Ako Maps Resources or any User Content.
                  </li>

                  <li>
                    CPL does not warrant that your User Content or the User Content shared with you during the Trial
                    Period will:
                    <ol>
                      <li>always be available for you to export; or</li>

                      <li>
                        once exported, appear or operate in the same way as it does on the Ako Maps Platform, or be in
                        any particular form or have any particular functionality.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li id="liability">
                Liability
                <ol>
                  <li>
                    CPL will not be liable to you (whether or not we were advised of, or knew of, the possibility of
                    such losses) in relation to any claim, loss, or damage arising out of or in connection with your use
                    of the Ako Maps Platform or these terms, including without limitation, under any tort (including
                    negligence), breach of contract, misrepresentation (other than fraudulent misrepresentation),
                    intellectual property infringement, under any statute or otherwise, for any:
                    <ol>
                      <li>indirect, special or consequential losses;</li>

                      <li>
                        losses or damages arising out of changes made to the content of the Ako Maps Platform by
                        unauthorised third parties; or
                      </li>

                      <li>loss or damage to your, or any third parties&apos; data or records.</li>
                    </ol>
                  </li>

                  <li>
                    If you are a consumer, nothing in these terms, including in{" "}
                    <Link href="#liability">clause 9.1</Link>, affects any rights or remedies you might have under the
                    Consumer Guarantees Act 1993.
                  </li>
                </ol>
              </li>
              <li id="personal-information">
                Personal information and electronic messages
                <ol>
                  <li>
                    We are committed to protecting your privacy. All personal information you submit to us, or that we
                    otherwise collect about you including:
                    <ol>
                      <li>when you register to use the Ako Maps Platform; and</li>

                      <li>during your use of the Ako Maps Platform,</li>
                    </ol>
                  </li>
                  will be held and used by us in accordance with our{" "}
                  <Link href="../privacy-policy">Privacy Policy</Link>.
                  <li>
                    Where you have given us your consent, including when you consent to receiving marketing messages
                    from CPL during the registration process, we will send you commercial electronic messages (including
                    emails and text messages) from CPL in relation to the Ako Maps Platform, and for CPL’s general
                    marketing purposes. You can unsubscribe from receiving these marketing messages by clicking on the
                    unsubscribe facility in one of the messages we send you.
                  </li>
                </ol>
              </li>
              <li id="termination">
                Termination
                <ol>
                  <li>
                    You may cancel your account and terminate these terms at any time during the trial period by
                    notifying CPL. You can give this notice by contacting us at{" "}
                    <Link href="mailto:office@cognitiveperformancelabs.com">office@cognitiveperformancelabs.com</Link>.
                  </li>

                  <li>
                    If we have reasonable grounds to believe that you have breached any of these terms we may give you
                    written notice of that breach. If you have not remedied the breach within 14 days of that notice, we
                    may cancel your account and terminate these terms, with immediate effect.
                  </li>

                  <li>
                    If CPL becomes unable to operate the Ako Maps Platform, or otherwise stops operating the Ako Maps
                    Platform for any reason, CPL may terminate these terms by giving you 14 days’ notice.
                  </li>

                  <li>
                    In the event that these terms expire and are not replaced after the Free Trial Period or are
                    terminated under <Link href="#termination">clause 11.1</Link>, your right and licence to access and
                    use the Ako Maps Platform ceases, except to the extent necessary to allow you to export copies of
                    your User Content or User Content shared with you through the Ako Maps Platform during the Free
                    Trial Period. Clauses <Link href="#definitions">1</Link>, <Link href="#account">3</Link>,{" "}
                    <Link href="#license">5.1</Link>, <Link href="#user-content">6.4</Link>,{" "}
                    <Link href="#usage">7</Link>, <Link href="#no-warranty">8</Link>, <Link href="#liability">9</Link>,{" "}
                    <Link href="#personal-information">10</Link>, <Link href="#termination">11.4</Link> and{" "}
                    <Link href="#termination">11.6</Link> will survive termination of these terms.
                  </li>

                  <li>
                    In the event that we terminate these terms under clause 11.2 or 11.3 your right and licence to
                    access and use the Ako Maps Platform, and to export any of your User Content or User Content shared
                    with you through the Ako Maps Platform during the Free Trial Period, ceases on termination.
                  </li>

                  <li>
                    If CPL becomes unable to operate the Ako Maps Platform, or otherwise stops operating the Ako Maps
                    Platform for any reason, any continuing rights you may have under clause 11.4 to export User Content
                    will cease.
                  </li>
                </ol>
              </li>
              <li id="changes">
                Changes to these terms
                <ol>
                  We may modify these terms at any time by making changes to the copy of these terms that appears on the
                  Ako Ako Maps Platform. We will notify you of changes have been made to these terms. If you believe
                  that the change in terms has a materially detrimental effect on you, you may terminate these terms at
                  any time with immediate effect.
                </ol>
              </li>

              <li id="general">
                General
                <ol>
                  <li>
                    If a court or any other competent authority decides that any part of these terms are unenforceable,
                    the remaining parts will remain enforceable and the parties will attempt to negotiate the
                    unenforceable part in good faith.
                  </li>

                  <li>
                    These terms are governed an interpreted in accordance with the laws of New Zealand. The parties
                    agree to submit to the exclusive jurisdiction of the Courts of New Zealand.
                  </li>

                  <li>
                    Ako Maps Platform is protected by reCAPTCHA and the Google{" "}
                    <Link href="https://policies.google.com/terms">Terms of Service</Link> applies.
                  </li>
                </ol>
              </li>
            </LegalList>
          </GridItem>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default TermsOfUse;

const GridItem = styled(Grid)`
  width: 75%;
  margin: auto;
`;

const List = styled.ol`
  list-style: decimal;
  z-index: 1;
  font-family: ${props => props.theme.font.light};
  ${props => props.theme.fontSize.xsmall}
  & li {
    display: list-item;
  }
`;
const LegalList = styled(List)`
  list-style: none;
  counter-reset: item;
  tab-index: 4;
  z-index: 0;
  padding-left: 16px;
  > li {
    padding-top: 96px;
    margin-top: -96px;
    padding-bottom: 16px;
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.regular};
    color: ${props => props.theme.color.secondary};
    counter-increment: item;
    display: list-item;
    > ol {
      font-family: ${props => props.theme.font.light};
      ${props => props.theme.fontSize.xsmall}
      color: black;
      padding-top: 8px;
      counter-reset: item;
      list-style: none;
    }
    > ol > li {
      line-height: 1.5;

      counter-increment: item;
      > ol > li {
        list-style-type: lower-alpha;
      }
    }
    > ol > li::before {
      content: counters(item, ".") " ";
    }
  }
  > li:before {
    content: counters(item, ".") " ";
  }
`;
const Link = styled.a`
  color: ${props => props.theme.color.secondary};

  &:visited {
    color: ${props => props.theme.color.secondary};
  }
`;
